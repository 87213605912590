import axios from "axios";

// auth.js
const state = {
  accessToken: sessionStorage.getItem("accessToken") || null,
  user: {
    username: sessionStorage.getItem("username") || null,
    jenis_akun: sessionStorage.getItem("role") || null,
    id_user: sessionStorage.getItem("id") || null,
  },
};

const mutations = {
  setToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setUser(state, user) {
    state.user = user;
  },
  clearAuthData(state) {
    state.accessToken = null;
    state.user = {};
  },
};

const actions = {
  async login({ commit }, { username, password }) {
    try {
      // Lakukan permintaan API untuk login
      const response = await axios.post("auth/auth/login", {
        username,
        password,
      });

      // Asumsikan bahwa respons API memiliki bidang 'token' dan 'user'
      const token = response.data.data.token;
      const user = response.data.data.user;

      // Lakukan mutasi untuk memperbarui Vuex
      commit("setToken", token);
      commit("setUser", user);

      // Simpan token di sessionStorage untuk login yang persisten
      sessionStorage.setItem("accessToken", token);
      sessionStorage.setItem("role", user.jenis_akun);
      sessionStorage.setItem("id", user.id_user);
      sessionStorage.setItem("username", user.username);

      window.location.reload();
    } catch (error) {
      console.error("Login gagal", error);
      throw error;
    }
  },
  logout({ commit }) {
    // Lakukan mutasi untuk membersihkan token dari Vuex state
    commit("clearAuthData");
    // Hapus token dari sessionStorage
    sessionStorage.clear();
    window.location.reload();
  },
};

const getters = {
  username: (state) => state.user.username,
  role: (state) => state.user.jenis_akun,
  token: (state) => state.accessToken,
  // tambahkan getters lain jika diperlukan
};

export default {
  state,
  mutations,
  actions,
  getters,
};
