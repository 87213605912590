export default [
  //akun
  {
    component: 'CNavGroup',
    name: 'Akun',
    to: '/akun',
    items: [
      {
        component: 'CNavItem',
        name: 'Beranda',
        to: '/akun/beranda',
      },
      {
        component: 'CNavGroup',
        name: 'Profilku',
        to: '/akun/profilku',
        items: [
          {
            component: 'CNavItem',
            name: 'Ubah Password',
            to: '/akun/profilku/ubahpassword',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Management User',
        to: '/akun/manageuser',
      },
      {
        component: 'CNavItem',
        name: 'Management Role',
        to: '/akun/managerole',
      },
    ],
  },

  //administrator akademik
  {
    component: 'CNavGroup',
    name: 'Administrator Akademik',
    to: '/adm-akademik',
    items: [
      {
        component: 'CNavItem',
        name: 'Beranda',
        to: '/adm-akademik/beranda',
      },
      {
        component: 'CNavGroup',
        name: 'Master',
        to: '/adm-akademik/master',
        items: [
          {
            component: 'CNavItem',
            name: 'Fakultas',
            to: '/adm-akademik/master/fakultas',
          },
          {
            component: 'CNavItem',
            name: 'Program Studi',
            to: '/adm-akademik/master/prodi',
          },
          {
            component: 'CNavItem',
            name: 'Kelas',
            to: '/adm-akademik/master/kelas',
          },
          {
            component: 'CNavItem',
            name: 'Ruangan',
            to: '/adm-akademik/master/ruangan',
          },
          {
            component: 'CNavItem',
            name: 'Semester',
            to: '/adm-akademik/master/semester',
          },
          {
            component: 'CNavItem',
            name: 'Shift',
            to: '/adm-akademik/master/shift',
          },
          {
            component: 'CNavItem',
            name: 'Tahun Ajar',
            to: '/adm-akademik/master/thnajar',
          },
          {
            component: 'CNavItem',
            name: 'Tahun Kurikulum',
            to: '/adm-akademik/master/kurikulum',
          },
          {
            component: 'CNavItem',
            name: 'Waktu',
            to: '/adm-akademik/master/waktu',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Pengaturan',
        to: '/adm-akademik/pengaturan',
        items: [
          {
            component: 'CNavItem',
            name: 'Jadwal Input Nilai',
            to: '/adm-akademik/pengaturan/jadwalinputnilai',
          },
          {
            component: 'CNavItem',
            name: 'Jadwal Registrasi KRS',
            to: '/adm-akademik/pengaturan/jadwalkrs',
          },
          {
            component: 'CNavItem',
            name: 'Jadwal Registrasi PMB',
            to: '/adm-akademik/pengaturan/jadwalpmb',
          },
        ],
      },
    ],
  },

  //finansial
  {
    component: 'CNavGroup',
    name: 'Finansial',
    to: '/finansial',
    items: [
      {
        component: 'CNavItem',
        name: 'Data Pembayaran',
        to: '/finansial/datapembayaran',
      },
      {
        component: 'CNavGroup',
        name: 'Laporan',
        to: '/finansial/laporan',
        items: [
          {
            component: 'CNavItem',
            name: 'Log Transaksi VA',
            to: '/finansial/laporan/logva',
          },
          {
            component: 'CNavItem',
            name: 'Laporan Piutang Mahasiswa',
            to: '/finansial/laporan/laporanpiutangmhs',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Master Finansial',
        to: '/finansial/master/',
        items: [
          {
            component: 'CNavItem',
            name: 'Komponen Biaya',
            to: '/finansial/master/komponenbiaya',
          },
          {
            component: 'CNavItem',
            name: 'Master Komponen Biaya',
            to: '/finansial/master/masterkomponenbiaya',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Update VA',
        to: '/finansial/updateva',
      },
      {
        component: 'CNavItem',
        name: 'Data Virtual Account',
        to: '/finansial/datava',
      },
      {
        component: 'CNavItem',
        name: 'Import Data Pembayaran',
        to: '/finansial/importpembayaran',
      },
    ],
  },

  //registrasi
  {
    component: 'CNavGroup',
    name: 'Registrasi',
    to: '/registrasi',
    items: [
      {
        component: 'CNavItem',
        name: 'Beranda Registrasi',
        to: '/registrasi/beranda',
      },
      {
        component: 'CNavItem',
        name: 'Pilih Mahasiswa',
        to: '/registrasi/pilihmahasiswa',
      },
      {
        component: 'CNavGroup',
        name: 'Jadwal',
        to: '/registrasi/jadwal',
        items: [
          {
            component: 'CNavItem',
            name: 'Lihat Jadwal Ujian',
            to: '/registrasi/jadwal/jadwalujian',
          },
          {
            component: 'CNavItem',
            name: 'Tambah Jadwal',
            to: '/jadwal/tambahjadwal',
          },
          {
            component: 'CNavItem',
            name: 'Lihat Jadwal',
            to: '/jadwal/lihatjadwal',
          },
          {
            component: 'CNavItem',
            name: 'Ruang Kosong',
            to: '/jadwal/ruangkosong',
          },
          {
            component: 'CNavItem',
            name: 'Import Jadwal',
            to: '/jadwal/importjadwal',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Mata Kuliah',
        to: '/registrasi/matkul',
        items: [
          {
            component: 'CNavItem',
            name: 'Mata Kuliah Tawar',
            to: '/registrasi/matkul/daftarmatkul',
          },
          {
            component: 'CNavItem',
            name: 'Impor Mata Kuliah',
            to: '/registrasi/matkul/impormatkul',
          },
          // {
          //   component: "CNavItem",
          //   name: "Alokasi Mata Kuliah",
          //   to: "/registrasi/matkul/alokasimatkul",
          // },
          // {
          //   component: "CNavItem",
          //   name: "Koordinator Mata Kuliah",
          //   to: "/registrasi/matkul/koordinatormatkul",
          // },
          // {
          //   component: "CNavItem",
          //   name: "Status Mata Kuliah",
          //   to: "/registrasi/matkul/statusmatkul",
          // },
          // {
          //   component: "CNavItem",
          //   name: "Mendaftarkan Mata Kuliah TA/PA",
          //   to: "/registrasi/matkul/tamatkul",
          // },
        ],
      },
    ],
  },

  //kurikulum
  {
    component: 'CNavGroup',
    name: 'Kurikulum',
    to: '/kurikulum',
    items: [
      {
        component: 'CNavItem',
        name: 'Beranda',
        to: '/kurikulum/beranda',
      },
      {
        component: 'CNavGroup',
        name: 'Kelola Kurikulum',
        to: '/kurikulum/kelolakurikulum',
        items: [
          {
            component: 'CNavItem',
            name: 'Lihat Kurikulum',
            to: '/kurikulum/kelolakurikulum/lihatkurikulum',
          },
          {
            component: 'CNavItem',
            name: 'Tambah Mata Kuliah',
            to: '/kurikulum/kelolakurikulum/tambahmatkul',
          },
          {
            component: 'CNavItem',
            name: 'Impor Mata kuliah',
            to: '/kurikulum/kelolakurikulum/impormatkul',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Persetujuan Kurikulum',
        to: '/kurikulum/persetujuankurikulum',
        items: [
          {
            component: 'CNavItem',
            name: 'Riwayat Kurikulum',
            to: '/kurikulum/persetujuankurikulum/riwayatkurikulum',
          },
          {
            component: 'CNavItem',
            name: 'Atur Approval',
            to: '/kurikulum/persetujuankurikulum/aturapproval',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Laporan Mata Kuliah',
        to: '/kurikulum/laporanmatkul',
      },
      {
        component: 'CNavItem',
        name: 'User Manual',
        to: '/kurikulum/usermanual',
      },
    ],
  },

  //beasiswa
  {
    component: 'CNavGroup',
    name: 'Beasiswa',
    to: '/beasiswa',
    items: [
      {
        component: 'CNavGroup',
        name: 'Beasiswa Mahasiswa',
        to: '/beasiswa/beasiswamahasiswa',
        items: [
          {
            component: 'CNavItem',
            name: 'Jenis Beasiswa Mahasiswa',
            to: '/beasiswa/beasiswamahasiswa/jenisbeasiswa',
          },
          {
            component: 'CNavItem',
            name: 'Penerima Beasiswa Mahasiswa',
            to: '/beasiswa/beasiswamahasiswa/penerimabeasiswamhs',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Potongan Mahasiswa Baru',
        to: '/beasiswa/beasiswamaba',
        items: [
          {
            component: 'CNavItem',
            name: 'Jenis Potongan Maba',
            to: '/beasiswa/beasiswamaba/jenispotongan',
          },
          // {
          //   component: "CNavItem",
          //   name: "Lihat Jenis Potongan Maba",
          //   to: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
          // },
          {
            component: 'CNavItem',
            name: 'Lihat Penerima Potongan',
            to: '/beasiswa/beasiswamaba/lihatpenerimabeasiswa',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Laporan Beasiswa',
        to: '/beasiswa/laporanbeasiswa',
        items: [
          {
            component: 'CNavItem',
            name: 'Laporan Beasiswa Per Semester',
            to: '/beasiswa/laporanbeasiswa/laporanbeasiswapersemester',
          },
          {
            component: 'CNavItem',
            name: 'Laporan Penerima Beasiswa',
            to: '/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa',
          },
          {
            component: 'CNavItem',
            name: 'Laporan Beasiswa Per Prodi',
            to: '/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi',
          },
          {
            component: 'CNavItem',
            name: 'Laporan Potongan',
            to: '/beasiswa/laporanbeasiswa/laporanpotongan',
          },
        ],
      },
      // {
      //   component: "CNavItem",
      //   name: "Lihat Jenis Beasiswa",
      //   to: "/beasiswa/lihatjenisbeasiswa",
      // },
    ],
  },

  //pmb
  {
    component: 'CNavGroup',
    name: 'PMB',
    to: '/pmb',
    items: [
      {
        component: 'CNavItem',
        name: 'Beranda',
        to: '/pmb/beranda',
      },
      {
        component: 'CNavItem',
        name: 'Daftar Peserta',
        to: '/pmb/daftarPeserta',
      },
      {
        component: 'CNavItem',
        name: 'Form Pengunduran Diri',
        to: '/pmb/pengunduranDiri',
      },
      {
        component: 'CNavGroup',
        name: 'Pembayaran',
        to: '/pmb/pembayaran',
        items: [
          {
            component: 'CNavItem',
            name: 'Data Pembayaran',
            to: '/pmb/pembayaran/inputPembayaran',
          },
        ],
      },

      {
        component: 'CNavGroup',
        name: 'Pengaturan',
        to: '/pmb/pengaturan',
        items: [
          {
            component: 'CNavItem',
            name: 'Generete Kelas',
            to: '/pmb/pengaturan/generetekelas',
          },
          {
            component: 'CNavItem',
            name: 'Jadwal Seleksi Registrasi',
            to: '/pmb/pengaturan/jadwalseleksireg',
          },
        ],
      },

      {
        component: 'CNavGroup',
        name: 'Laporan',
        to: '/pmb/Laporan',
        items: [
          {
            component: 'CNavItem',
            name: 'Laporan Peserta PMB',
            to: '/pmb/Laporan/PesertaPmb',
          },

          {
            component: 'CNavItem',
            name: 'Laporan Pembayaran',
            to: '/pmb/Laporan/laporanPembayaran',
          },
        ],
      },

      {
        component: 'CNavItem',
        name: 'Registrasi',
        to: '/pmb/daftarPesertaOnSite',
      },
      {
        component: 'CNavGroup',
        name: 'Bantuan',
        to: '/pmb/bantuan',
        items: [
          {
            component: 'CNavItem',
            name: 'Download Manual Pengguna',
            to: '/pmb/bantuan/Manual',
          },
        ],
      },
    ],
  },

  //nilai dosen
  {
    component: 'CNavGroup',
    name: 'Nilai Dosen',
    to: '/nilaidosen',
    items: [
      {
        component: 'CNavItem',
        name: 'Beranda_Nilai',
        to: '/nilaidosen/berandanilai',
      },
      {
        component: 'CNavItem',
        name: 'Riwayat Input Nilai',
        to: '/nilaidosen/riwayatinput',
      },
      {
        component: 'CNavItem',
        name: 'List Mata Kuliah',
        to: '/nilaidosen/listmatkul',
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
