<template>
  <CCard class="mb-3">
    <CCardHeader class="cardHeader">
      <div class="d-md-flex">
        <p class="mb-0 me-2 text-white">Lokasi :</p>
        <AppBreadcrumb />
      </div>
    </CCardHeader>
    <CCardBody class="h-full">
      <router-view />
    </CCardBody>
  </CCard>
</template>

<script>
import AppBreadcrumb from "./AppBreadcrumb.vue";
export default {
  name: "AppCard",
  components: { AppBreadcrumb },
};
</script>

<style>
.cardHeader {
  background-image: url("../assets/images/diamond.png");
  background-size: cover;
  display: flex;
}
</style>
