<template>
  <div class="d-flex justify-content-center align-items-center min-vh-100">
    <img src="../../assets/images/notfound.svg" style="width: 30%" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Page404",
};
</script>
