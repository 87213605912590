<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu>
      <CDropdownItem class="custom-dropdown-item" style="cursor: pointer">
        <CIcon icon="cilUser" /> Ubah Profil
      </CDropdownItem>
      <CDropdownItem class="custom-dropdown-item" style="cursor: pointer">
        <CIcon icon="cilLanguage" /> ENG
      </CDropdownItem>
      <CDropdownItem class="custom-dropdown-item" style="cursor: pointer">
        <CIcon icon="cilSettings" /> Pengaturan Akun
      </CDropdownItem>
      <CDropdownItem class="custom-dropdown-item" style="cursor: pointer">
        <CIcon icon="cilBell" /> Pengaturan Notifikasi
      </CDropdownItem>
      <CDropdownItem
        class="custom-dropdown-item"
        style="cursor: pointer; background-color: activeColor"
        @click="logout"
      >
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from "@/assets/images/avatars/8.jpg";
export default {
  name: "AppHeaderDropdownAccnt",
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    };
  },
  methods: {
    logout() {
      // Hapus data dari local storage
      sessionStorage.clear();

      // Navigasi ke rute pages/login
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
.custom-dropdown-item:active {
  background-color: #846aad !important;
  color: white !important;
}
</style>
