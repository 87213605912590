// axiosInterceptor.js

import axios from 'axios';

function setupAxiosInterceptor() {
  axios.interceptors.response.use(
    (response) => {
      // Tangani response sukses
      return response;
    },
    (error) => {
      // Tangani error response
      if (error.response.status === 401) {
        const currentRoute = window.location.hash;
        if (currentRoute !== '#/pages/login') {
          return handleUnauthorizedError();
        }
      }
      return Promise.reject(error);
    }
  );

  function handleUnauthorizedError() {
    sessionStorage.clear();
    window.location.reload();
  }

  return axios; // Kembalikan instance Axios
}

export default setupAxiosInterceptor;
