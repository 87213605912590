export default [
  {
    component: "CNavGroup",
    name: "Akun",
    to: "/akun",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/akun/beranda",
      },
      {
        component: "CNavGroup",
        name: "Profilku",
        to: "/akun/profilku",
        items: [
          {
            component: "CNavItem",
            name: "Ubah Password",
            to: "/akun/profilku/ubahpassword",
          },
        ],
      },
    ],
  },
  // {
  //   component: "CNavGroup",
  //   name: "Registrasi",
  //   to: "/registrasi",
  //   items: [
  //     {
  //       component: "CNavGroup",
  //       name: "Jadwal",
  //       to: "/registrasi/jadwal",
  //       items: [
  //         {
  //           component: "CNavItem",
  //           name: "Jadwal Ujian Mahasiswa",
  //           to: "/registrasi/jadwal/jadwalujianmhs",
  //         },
  //       ],
  //     },
  //   ],
  // },
  //registrasi
  {
    component: "CNavGroup",
    name: "Registrasi",
    to: "/registrasi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Registrasi",
        to: "/registrasi/beranda",
      },
      {
        component: "CNavItem",
        name: "Tagihan Registrasi",
        to: "/registrasi/tagihanregis",
      },
      {
        component: "CNavItem",
        name: "Registrasi Mata Kuliah",
        to: "/registrasi/registrasimatkul",
      },
      // {
      //   component: "CNavItem",
      //   name: "History Proses Registrasi",
      //   to: "/registrasi/historyregis",
      // },
      // {
      //   component: "CNavItem",
      //   name: "Arsip KSM",
      //   to: "/registrasi/arsipksm",
      // },
      {
        component: "CNavItem",
        name: "Status Registrasi Mahasiswa",
        to: "/registrasi/statusregis",
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/registrasi/jadwal",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Ujian Mahasiswa",
            to: "/registrasi/jadwal/jadwalujianmhs",
          },
        ],
      },
      // {
      //   component: "CNavGroup",
      //   name: "Jadwal",
      //   to: "/jadwal-kaprodi",
      //   items: [
      //     {
      //       component: "CNavItem",
      //       name: "Tambah Jadwal",
      //       to: "/jadwal-kaprodi/tambahjadwal",
      //     },
      //     {
      //       component: "CNavItem",
      //       name: "Lihat Jadwal",
      //       to: "/jadwal-kaprodi/lihatjadwal",
      //     },
      //     {
      //       component: "CNavItem",
      //       name: "Ruang Kosong",
      //       to: "/jadwal-kaprodi/ruangkosong",
      //     },
      //     {
      //       component: "CNavItem",
      //       name: "Import Jadwal",
      //       to: "/jadwal-kaprodi/importjadwal",
      //     },
      //   ],
      // },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
