import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue';
import { RouterLink, useRoute } from 'vue-router';

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue';
import nav from '@/_nav.js';
import nav_mhs from '@/_nav-mhs';
import nav_adm_ads from '@/_nav-adm-ads';
import nav_adm from '@/_nav-adm';
import nav_keu from '@/_nav-keu';
import nav_dosen from '@/_nav-dosen';
import nav_new from '@/_nav-new';
import nav_nonmhs from '@/_nav-nonmhs';
import nav_kaprodi from '@/_nav-kaprodi';
import nav_camaba from '@/_nav-camaba';
import nav_camaba_before from '@/_nav-camaba-before';
import nav_baa from '@/_nav-baa';
import nav_baak from '@/_nav-baak';

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '');

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false;
  }

  if (route.hash === link) {
    return true;
  }

  const currentPath = normalizePath(route.path);
  const targetPath = normalizePath(link);

  return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true;
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child));
  }

  return false;
};

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute();
    const firstRender = ref(true);

    onMounted(() => {
      firstRender.value = false;
    });

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              // h(resolveComponent("CIcon"), {
              //   customClassName: "nav-icon",
              //   name: item.icon,
              // }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          }
        );
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    href: props.href,
                    onClick: () => props.navigate(),
                  },
                  {
                    default: () => [
                      // item.icon &&
                      //   h(resolveComponent("CIcon"), {
                      //     customClassName: "nav-icon",
                      //     name: item.icon,
                      //   }),
                      item.name,
                      // item.badge &&
                      //   h(
                      //     CBadge,
                      //     {
                      //       class: "ms-auto",
                      //       color: item.badge.color,
                      //     },
                      //     {
                      //       default: () => item.badge.text,
                      //     }
                      //   ),
                    ],
                  }
                ),
            }
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            }
          );
    };

    return () => {
      const role = sessionStorage.getItem('role');
      let renderNav;
      switch (role) {
        case 'Mahasiswa':
          renderNav = nav_mhs;
          break;
        case 'Camaba':
          if (sessionStorage.getItem('status') === 'BELUM REGISTRASI') {
            renderNav = nav_camaba_before;
          } else {
            renderNav = nav_camaba;
          }
          break;
        // case "finansial":
        //   renderNav = nav_keu;
        //   break;
        // case "baak":
        //   renderNav = nav_baak;
        //   break;
        default:
          renderNav = nav_nonmhs;
          break;
      }

      return h(
        CSidebarNav,
        {},
        {
          default: () => renderNav.map((item) => renderItem(item)),
        }
      );
    };
  },
});
export { AppSidebarNav };
