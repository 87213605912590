export default [
  {
    component: "CNavTitle",
    name: "Mahasiswa",
  },
  {
    component: "CNavGroup",
    name: "Akun",
    to: "/akun",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/akun/beranda",
      },
      {
        component: "CNavGroup",
        name: "Profilku",
        to: "/akun/profilku",
        items: [
          {
            component: "CNavItem",
            name: "Edit Profil",
            to: "/akun/profilku/editprofil",
          },
          {
            component: "CNavItem",
            name: "Ubah Password",
            to: "/akun/profilku/ubahpassword",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Unduh Berkas",
        to: "/akun/unduhberkas",
      },
      {
        component: "CNavItem",
        name: "Riwayat Login",
        to: "/akun/riwayatlogin",
      },
      {
        component: "CNavGroup",
        name: "Pengawas Ujian",
        to: "/akun/pengawasujian",
        items: [
          {
            component: "CNavItem",
            name: "Pendaftaran Pengawas Ujian",
            to: "/akun/pengawasujian/pendaftaranpengawas",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Ekuivalensi",
    to: "/ekuivalensi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/ekuivalensi/beranda",
      },
      {
        component: "CNavGroup",
        name: "Ekuivalensi Pindahan",
        to: "/ekuivalensi/pindahan",
        items: [
          {
            component: "CNavItem",
            name: "Proses Ekuivalensi",
            to: "/ekuivalensi/pindahan/proses",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/ekuivalensi/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Proses Ekuivalensi",
            to: "/ekuivalensi/bantuan/manualpengguna",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Finansial",
    to: "/finansial",
    items: [
      {
        component: "CNavGroup",
        name: "Data Transaksi Bank",
        to: "/finansial/datatransaksi/konfirmasi",
        items: [
          {
            component: "CNavItem",
            name: "Konfirmasi Transaksi Pembayaran",
            to: "/finansial/datatransaksi/konfirmasi",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Surat Keterangan Bebas Tunggakan",
        to: "/finansial/bebastunggakan",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Nilai",
    to: "/nilai",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Nilai",
        to: "/nilai/beranda",
      },
      {
        component: "CNavGroup",
        name: "KHS",
        to: "/nilai/khs",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Ekivalensi",
            to: "/nilai/khs/lihatekivalensi",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai",
            to: "/nilai/khs/lihatnilai",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai Sementara",
            to: "/nilai/khs/lihatnilaisementara",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Statistik Nilai",
        to: "/nilai/statistiknilai",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Status Akademik",
        to: "/nilai/pengajuanstatusakademik",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Pembayaran",
    to: "/pembayaran",
    items: [
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pembayaran/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Detail Pembayaran Mahasiswa",
            to: "/pembayaran/pembayaran/detailpembayaranmahasiswa",
          },
          {
            component: "CNavItem",
            name: "Tagihan",
            to: "/pembayaran/pembayaran/tagihan",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pembayaran/pembayaran/konfirmasipembayaran",
          },
          {
            component: "CNavItem",
            name: "Keterlambatan Registrasi Semester",
            to: "/pembayaran/pembayaran/keterlambatanregistrasisemester",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Perkuliahan",
    to: "/perkuliahan",
    items: [
      {
        component: "CNavItem",
        name: "Kehadiran",
        to: "/perkuliahan/kehadiran",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb/beranda",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb/pengunduranDiri",
      },
      {
        component: "CNavItem",
        name: "Daftar Peserta",
        to: "/pmb/daftarPeserta",
      },
      {
        component: "CNavItem",
        name: "Registrasi",
        to: "/pmb/daftarPesertaOnSite",
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb/Laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb/Laporan/Agama",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi Per Tanggal",
            to: "/pmb/Laporan/LaporanRegistrasiPerTanggal",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb/Laporan/laporanPembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb/Laporan/laporanPembayaranCetakBA",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi On Site per Tanggal",
            to: "/pmb/Laporan/LaporanOnsite",
          },
          {
            component: "CNavItem",
            name: "Laporan Kontak Peserta",
            to: "/pmb/Laporan/LaporanKontakPeserta",
          },
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb/Laporan/PesertaPmb",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb/Laporan/LaporanDistribusiJK",
          },
          {
            component: "CNavItem",
            name: "Laporan Dokumen Regonsite",
            to: "/pmb/Laporan/DokumenRegonsite",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb/pembayaran/inputPembayaran",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/pmb/pembayaran/beasiswa",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pmb/pembayaran/KonfirmasiPembayaran",
          },
        ],
      },

      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/pmb/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "List Dokumen Peserta PMB",
            to: "/pmb/pengaturan/ListDokumen",
          },
          {
            component: "CNavItem",
            name: "Input pembayaran Finnet",
            to: "/pmb/pengaturan/InputFinnet",
          },
          // {
          //   component: "CNavItem",
          //   name: "Download Manual Pengguna",
          //   to: "/pmb/pengaturan/Manual",
          // },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/pmb/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Download Manual Pengguna",
            to: "/pmb/bantuan/Manual",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Presensi",
        to: "/presensi/beranda",
      },
      {
        component: "CNavItem",
        name: "RFID Log",
        to: "/presensi/rfidlog",
      },
      {
        component: "CNavItem",
        name: "Kehadiran",
        to: "/presensi/kehadiran",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "TA/PA",
    to: "/ta_pa",
    items: [
      {
        component: "CNavItem",
        name: "Beranda TA/PA",
        to: "/ta_pa/beranda",
      },
      {
        component: "CNavGroup",
        name: "Daftar TA/PA",
        to: "/ta_pa/daftarta_pa",
        items: [
          {
            component: "CNavItem",
            name: "Status TA/PA Mahasiswa",
            to: "/ta_pa/daftarta_pa/statusta_pamahasiswa",
          },
          {
            component: "CNavItem",
            name: "Daftar TA/PA yang Sedang Berlangsung",
            to: "/ta_pa/daftarta_pa/daftarta_payangsedangberlangsung",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Jadwal",
        to: "/ta_pa/jadwal",
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/ta_pa/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Manual Pengguna",
            to: "/ta_pa/bantuan/manualpengguna",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "TAK Mahasiswa",
    to: "/tak",
    items: [
      {
        component: "CNavItem",
        name: "Beranda TAK",
        to: "/tak/beranda",
      },
      {
        component: "CNavItem",
        name: "Input TAK Mahasiswa",
        to: "/tak/inputtakmahasiswa",
      },
      {
        component: "CNavItem",
        name: "Daftar TAK Mahasiswa",
        to: "/tak/daftartakmahasiswa",
      },
      {
        component: "CNavItem",
        name: "TAK Proses",
        to: "/tak/takproses",
      },
      {
        component: "CNavItem",
        name: "TAK Usang",
        to: "/tak/takusang",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Registrasi",
    to: "/registrasi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Registrasi",
        to: "/registrasi/beranda",
      },
      {
        component: "CNavGroup",
        name: "Registrasi",
        to: "/registrasi/reg",
        items: [
          {
            component: "CNavItem",
            name: "Tagihan Register",
            to: "/registrasi/reg/tagihanregis",
          },
          {
            component: "CNavItem",
            name: "Registrasi Mata Kuliah",
            to: "/registrasi/reg/registrasimatkul",
          },
          {
            component: "CNavItem",
            name: "Status Registrasi Mahasiswa",
            to: "/registrasi/reg/statusregis",
          },
          {
            component: "CNavItem",
            name: "Arsip KSM",
            to: "/registrasi/reg/arsipksm",
          },
          {
            component: "CNavItem",
            name: "History Proses Registrasi",
            to: "/registrasi/reg/historyregis",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/registrasi/jadwal",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Mahasiswa",
            to: "/registrasi/jadwal/jadwalmhs",
          },
          {
            component: "CNavItem",
            name: "Jadwal Ujian Mahasiswa",
            to: "/registrasi/jadwal/jadwalujianmhs",
          },
          {
            component: "CNavItem",
            name: "Kalender Akademik",
            to: "/registrasi/jadwal/kalenderakademik",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/registrasi/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Manual Pengguna",
            to: "/registrasi/bantuan",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Perwalian",
    to: "/kemahasiswaan",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Perwalian",
        to: "/perwalian/beranda",
      },
      {
        component: "CNavGroup",
        name: "Konsultasi",
        to: "/perwalian/konsultasi",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Pesan",
            to: "/perwalian/konsultasi/lihatpesan",
          },
          {
            component: "CNavItem",
            name: "Kirim Pesan",
            to: "/perwalian/konsultasi/kirimpesan",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Data Akademik",
        to: "/perwalian/dataakademik",
      },
      {
        component: "CNavItem",
        name: "Panduan",
        to: "/perwalian/panduan",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Wisuda",
    to: "/wisuda",
    items: [
      {
        component: "CNavItem",
        name: "Pendaftaran",
        to: "/wisuda/pendaftaran",
      },
      {
        component: "CNavItem",
        name: "Beranda Wisuda",
        to: "/wisuda/beranda",
      },
    ],
  },
  {
    name: "Kurikulum",
    to: "/kurikulum",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Kurikulum",
        to: "/kurikulum/beranda",
      },
      {
        component: "CNavGroup",
        name: "Silabus",
        to: "/kurikulum/silabus",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Pesan",
            to: "/kurikulum/silabus/lihatrps",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Kemahasiswaan",
    to: "/kemahasiswaan",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Kemahasiswaan",
        to: "/kemahasiswaan/beranda",
      },
      {
        component: "CNavGroup",
        name: "Manajemen Lomba",
        to: "/kemahasiswaan/manajemenlomba",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Lomba",
            to: "/kemahasiswaan/manajemenlomba/lihatlomba",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Manajemen Proposal",
        to: "/kemahasiswaan/manajemenproposal",
        items: [
          {
            component: "CNavItem",
            name: "Proposal Ormawa",
            to: "/kemahasiswaan/manajemenproposal/ormawa",
          },
          {
            component: "CNavItem",
            name: "Proposal Lomba",
            to: "/kemahasiswaan/manajemenproposal/lomba",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "SKPI",
    to: "/skpi",
    items: [
      {
        component: "CNavItem",
        name: "TAK & Informasi Lainnya",
        to: "/skpi/takinfo",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Survey",
    to: "/survey",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/survey/beranda",
      },
      {
        component: "CNavItem",
        name: "Kuesioner",
        to: "/survey/kuesioner",
      },
      {
        component: "CNavItem",
        name: "Bantuan",
        to: "/survey/bantuan",
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Admin Admission",
  },
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb/beranda",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb/pengunduranDiri",
      },
      {
        component: "CNavItem",
        name: "Daftar Peserta",
        to: "/pmb/daftarPeserta",
      },
      {
        component: "CNavItem",
        name: "Registrasi",
        to: "/pmb/daftarPesertaOnSite",
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb/Laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb/Laporan/Agama",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi Per Tanggal",
            to: "/pmb/Laporan/LaporanRegistrasiPerTanggal",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb/Laporan/laporanPembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb/Laporan/laporanPembayaranCetakBA",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi On Site per Tanggal",
            to: "/pmb/Laporan/LaporanOnsite",
          },
          {
            component: "CNavItem",
            name: "Laporan Kontak Peserta",
            to: "/pmb/Laporan/LaporanKontakPeserta",
          },
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb/Laporan/PesertaPmb",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb/Laporan/LaporanDistribusiJK",
          },
          {
            component: "CNavItem",
            name: "Laporan Dokumen Regonsite",
            to: "/pmb/Laporan/DokumenRegonsite",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb/pembayaran/inputPembayaran",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/pmb/pembayaran/beasiswa",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pmb/pembayaran/KonfirmasiPembayaran",
          },
        ],
      },

      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/pmb/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "List Dokumen Peserta PMB",
            to: "/pmb/pengaturan/ListDokumen",
          },
          {
            component: "CNavItem",
            name: "Input pembayaran Finnet",
            to: "/pmb/pengaturan/InputFinnet",
          },
          // {
          //   component: "CNavItem",
          //   name: "Download Manual Pengguna",
          //   to: "/pmb/pengaturan/Manual",
          // },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/pmb/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Download Manual Pengguna",
            to: "/pmb/bantuan/Manual",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Finansial Admin Admission",
    to: "/finansial-adm",
    items: [
      {
        component: "CNavGroup",
        name: "Pembayaran Reguler",
        to: "/finansial-adm/pemabayaranreg",
        items: [
          {
            component: "CNavItem",
            name: "Data Pembayaran",
            to: "/finansial-adm/pemabayaranreg/datapembayaran",
          },
        ],
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Admission",
  },
  {
    component: "CNavGroup",
    name: "PMB Admission",
    to: "/pmb-adm",
    items: [
      {
        component: "CNavItem",
        name: "Import Participant PMB",
        to: "/pmb-adm/importpmb",
      },
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb-adm/beranda",
      },
      {
        component: "CNavItem",
        name: "Alur Sistem",
        to: "/pmb-adm/alursistem",
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb-adm/laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Kontak Peserta",
            to: "/pmb-adm/laporan/kontakpeserta",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb-adm/laporan/pembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb-adm/laporan/CetakBa",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi Per Tanggal",
            to: "/pmb-adm/laporan/regispertanggal",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb-adm/laporan/agama",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb-adm/laporan/gender",
          },
          {
            component: "CNavItem",
            name: "Laporan Ukuran Baju",
            to: "/pmb-adm/laporan/ukuranbaju",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Registrasi",
        to: "/pmb-adm/registrasi",
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Keuangan",
  },
  {
    component: "CNavGroup",
    name: "PMB Keuangan",
    to: "/pmb-keu",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb-keu/beranda",
      },
      {
        component: "CNavItem",
        name: "Alur Sistem",
        to: "/pmb-keu/alursistem",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb-keu/pengajuanundurdiripeserta",
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb-keu/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Pengembalian Pembayaran",
            to: "/pmb-keu/pembayaran/pengembalian",
          },
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb-keu/pembayaran/input",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pmb-keu/pembayaran/konfirmasi",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/pmb-keu/pembayaran/beasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb-keu/laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb-keu/laporan/laporanpembayarancetakba",
          },
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb-keu/laporan/laporanpesertapmb",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb-keu/laporan/laporandistribusijk",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb-keu/laporan/laporanpembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb-keu/laporan/laporandistribusiagama",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Finansial Keuangan",
    to: "/finansial-keu",
    items: [
      {
        component: "CNavGroup",
        name: "Daftar Detail Laporan",
        to: "/finansial-keu/daftardetaillaporan",
        items: [
          {
            component: "CNavItem",
            name: "Finance Laporan Detail MABA",
            to: "/finansial-keu/daftardetaillaporan/financelaporandetailmaba",
          },
          {
            component: "CNavItem",
            name: "Finance Laporan Detail MALA",
            to: "/finansial-keu/daftardetaillaporan/financelaporandetailmala",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pembayaran Reguler",
        to: "/finansial-keu/pembayaranreguler",
        items: [
          {
            component: "CNavItem",
            name: "Data Pembayaran",
            to: "/finansial-keu/pembayaranreguler/datapembayaran",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/finansial-keu/pembayaranreguler/beasiswa",
          },
          {
            component: "CNavItem",
            name: "Status Mahasiswa",
            to: "/finansial-keu/pembayaranreguler/statusmahasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Data Transaksi Bank",
        to: "/finansial-keu/datatransaksibank",
        items: [
          {
            component: "CNavItem",
            name: "Konfirmasi Transaksi Pembayaran",
            to: "/finansial-keu/datatransaksibank/konfirmasitransaksipembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Transaksi Rekon",
            to: "/finansial-keu/datatransaksibank/laporantransaksirekon",
          },
          {
            component: "CNavItem",
            name: "Transaksi Rekening Koran",
            to: "/finansial-keu/datatransaksibank/rekeningkoran",
          },
        ],
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Dosen",
  },
  {
    component: "CNavGroup",
    name: "Akun_Dosen",
    to: "/akun_dosen",
    items: [
      {
        component: "CNavGroup",
        name: "ProfilKu",
        to: "/profilku",
        items: [
          {
            component: "CNavItem",
            name: "Ubah Password",
            to: "/akun_dosen/profilku/ubahpassword",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Riwayat Login",
        to: "/akun_dosen/riwayatlogin",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Nilai Dosen",
    to: "/nilaidosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda_Nilai",
        to: "/nilaidosen/berandanilai",
      },
      {
        component: "CNavItem",
        name: "Riwayat Input Nilai",
        to: "/nilaidosen/riwayatinput",
      },
      {
        component: "CNavItem",
        name: "List Mata Kuliah",
        to: "/nilaidosen/listmatkul",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Registrasi Dosen",
    to: "/registrasidosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/registrasidosen/berandaregdosen",
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/registrasidosen/jadwal",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Jadwal Mahasiswa Keseluruhan",
            to: "/registrasidosen/jadwal/lihatjadwal",
          },
          {
            component: "CNavItem",
            name: "Rencana Mengajar Dosen",
            to: "/registrasidosen/jadwal/rencanamengajar",
          },
          {
            component: "CNavItem",
            name: "Lihat Jadwal Ujian",
            to: "/registrasidosen/jadwal/lihatjadwalujian",
          },
          {
            component: "CNavItem",
            name: "Jadwal Ujian Mahasiswa",
            to: "/registrasidosen/jadwal/jadwalujianmahasiswa",
          },
          {
            component: "CNavItem",
            name: "Jadwal Dosen",
            to: "/registrasidosen/jadwal/jadwaldosen",
          },
          {
            component: "CNavItem",
            name: "Kalender Akademik",
            to: "/registrasidosen/jadwal/kalenderak",
          },
        ],
      },
    ],
  },
  {
    name: "Kurikulum Dosen",
    to: "/kurikulumdosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Dosen",
        to: "/kurikulumdosen/berandadosen",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "PPM",
    to: "/ppm",
    items: [
      {
        component: "CNavGroup",
        name: "Acara",
        to: "/ppm/acara",
        items: [
          {
            component: "CNavItem",
            name: "Jurnal Saya",
            to: "/ppm/acara/jurnalsaya",
          },
          {
            component: "CNavItem",
            name: "Penelitian",
            to: "/ppm/acara/penelitian",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Dokumen",
        to: "/ppm/dokumen",
        items: [
          {
            component: "CNavItem",
            name: "Dokumen Pendukung",
            to: "/ppm/dokumen/dokumenpendukung",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "TA/PA Dosen",
    to: "/tapa_dos",
    items: [
      {
        component: "CNavItem",
        name: "Beranda TA/PA Dosen",
        to: "/tapa_dos/beranda",
      },
      {
        component: "CNavItem",
        name: "Daftar Mahasiswa Bimbingan yang Sudah Sidang",
        to: "/tapa_dos/daftarmahasiswabimbingansudahsidang",
      },
      {
        component: "CNavItem",
        name: "Daftar Mahasiswa Diuji",
        to: "/tapa_dos/daftarmahasiswadiuji",
      },
      {
        component: "CNavItem",
        name: "Daftar Mahasiswa Bimbingan",
        to: "/tapa_dos/daftarmahasiswabimbingan",
      },
      {
        component: "CNavItem",
        name: "Jadwal TA/PA Mahasiswa",
        to: "/tapa_dos/jadwaltapamahasiswa",
      },
      {
        component: "CNavItem",
        name: "Referensi",
        to: "/tapa_dos/referensi",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi-dosen/bap",
    items: [
      {
        component: "CNavGroup",
        name: "Kehadiran Mahasiswa",
        to: "/presensi-dosen/kehadiranmhs",
        items: [
          {
            component: "CNavItem",
            name: "Kehadiran per Perkuliahan",
            to: "/presensi-dosen/kehadiranmhs/per-perkuliahan",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Jadwal Dosen",
        to: "/presensi-dosen/jadwaldosen",
      },
      {
        component: "CNavItem",
        name: "Berita Acara Perkuliahan",
        to: "/presensi-dosen/bap",
      },
      {
        component: "CNavItem",
        name: "Cetak Daftar Hadir",
        to: "/presensi-dosen/cetakdaftarhadir",
      },
      {
        component: "CNavItem",
        name: "Evaluasi Pembelajaran",
        to: "/presensi-dosen/evaluasipembelajaran",
      },
      {
        component: "CNavItem",
        name: "Honor Kehadiran",
        to: "/presensi-dosen/honor",
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Kaprodi",
  },
  {
    component: "CNavGroup",
    name: "Kurikulum",
    to: "/kurikulum-kaprodi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/kurikulum-kaprodi/beranda",
      },
      {
        component: "CNavGroup",
        name: "Kelola Kurikulum",
        to: "/kurikulum-kaprodi/kelolakurikulum",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Kurikulum",
            to: "/kurikulum-kaprodi/kelolakurikulum/lihatkurikulum",
          },
          {
            component: "CNavItem",
            name: "Tambah Mata Kuliah",
            to: "/kurikulum-kaprodi/kelolakurikulum/tambahmatkul",
          },
          {
            component: "CNavItem",
            name: "Kelola Peminatan Program Studi",
            to: "/kurikulum-kaprodi/kelolakurikulum/peminatanprodi",
          },
          {
            component: "CNavItem",
            name: "Impor Mata kuliah",
            to: "/kurikulum-kaprodi/kelolakurikulum/impormatkul",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Persetujuan Kurikulum",
        to: "/kurikulum-kaprodi/persetujuankurikulum",
        items: [
          {
            component: "CNavItem",
            name: "Riwayat Kurikulum",
            to: "/kurikulum-kaprodi/persetujuankurikulum/riwayatkurikulum",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Silabus",
        to: "/kurikulum-kaprodi/silabus",
        items: [
          {
            component: "CNavItem",
            name: "Atur RPS",
            to: "/kurikulum-kaprodi/silabus/aturrps",
          },
          {
            component: "CNavItem",
            name: "Lihat RPS",
            to: "/kurikulum-kaprodi/silabus/lihatrps",
          },
          {
            component: "CNavItem",
            name: "Status Input RPS",
            to: "/kurikulum-kaprodi/silabus/statusinputrps",
          },
          {
            component: "CNavItem",
            name: "Ubah RPS",
            to: "/kurikulum-kaprodi/silabus/ubahrps",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Registrasi",
    to: "/registrasi-kaprodi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/registrasi-kaprodi/beranda",
      },
      {
        component: "CNavItem",
        name: "Pilih Mahasiswa",
        to: "/registrasi-kaprodi/pilihmahasiswa",
      },
      {
        component: "CNavGroup",
        name: "Registrasi",
        to: "/registrasi-kaprodi/regis",
        items: [
          {
            component: "CNavItem",
            name: "Registrasi Mata Kuliah",
            to: "/registrasi-kaprodi/regis/regismatkul",
          },
          {
            component: "CNavItem",
            name: "Status Registrasi Mahasiswa",
            to: "/registrasi-kaprodi/regis/statusmahasiswa ",
          },
          {
            component: "CNavItem",
            name: "Arsip KSM",
            to: "/registrasi-kaprodi/regis/arsip",
          },
          {
            component: "CNavItem",
            name: "History Proses Registrasi",
            to: "/registrasi-kaprodi/regis/history",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Mata Kuliah",
        to: "/registrasi-kaprodi/matkul",
        items: [
          {
            component: "CNavItem",
            name: "Daftar Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/daftarmatkul",
          },
          {
            component: "CNavItem",
            name: "Tambah Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/tambahmatkul",
          },
          {
            component: "CNavItem",
            name: "Impor Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/impormatkul",
          },
          {
            component: "CNavItem",
            name: "Alokasi Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/alokasimatkul",
          },
          {
            component: "CNavItem",
            name: "Koordinator Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/koordinatormatkul",
          },
          {
            component: "CNavItem",
            name: "Status Mata Kuliah",
            to: "/registrasi-kaprodi/matkul/statusmatkul",
          },
          {
            component: "CNavItem",
            name: "Mendaftarkan Mata Kuliah TA/PA",
            to: "/registrasi-kaprodi/matkul/tamatkul",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/registrasi-kaprodi/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "Aturan Maks SKS",
            to: "/registrasi-kaprodi/pengaturan/aturansks",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Master",
        to: "/registrasi-kaprodi/master",
        items: [
          {
            component: "CNavItem",
            name: "Ruangan",
            to: "/registrasi-kaprodi/master/ruangan",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/jadwal-kaprodi",
        items: [
          {
            component: "CNavItem",
            name: "Tambah Jadwal",
            to: "/jadwal-kaprodi/tambahjadwal",
          },
          {
            component: "CNavItem",
            name: "Lihat Jadwal",
            to: "/jadwal-kaprodi/lihatjadwal",
          },
          {
            component: "CNavItem",
            name: "Ruang Kosong",
            to: "/jadwal-kaprodi/ruangkosong",
          },
          {
            component: "CNavItem",
            name: "Import Jadwal",
            to: "/jadwal-kaprodi/importjadwal",
          },
        ],
      },
    ],
  },

  {
    component: "CNavGroup",
    name: "Perwalian",
    to: "/perwalian-kaprodi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/perwalian-kaprodi/beranda",
      },
      {
        component: "CNavItem",
        name: "Mahasiswa",
        to: "/perwalian-kaprodi/mahasiswa",
      },
      {
        component: "CNavItem",
        name: "Catatan LKS",
        to: "/perwalian-kaprodi/catatanlks",
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "BAA",
  },
  {
    component: "CNavGroup",
    name: "Feeder",
    to: "/feeder",
    items: [
      {
        component: "CNavItem",
        name: "Aktivitas Mahasiswa",
        to: "/feeder/aktivitasmahasiswa",
      },
      {
        component: "CNavGroup",
        name: "Mahasiswa",
        to: "/feeder/mahasiswa",
        items: [
          {
            component: "CNavItem",
            name: "Data Mahasiswa",
            to: "/feeder/mahasiswa/datamahasiswa",
          },
          {
            component: "CNavItem",
            name: "Aktivitas Perkuliahan",
            to: "/feeder/mahasiswa/aktivitasperkuliahan",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Dosen",
        to: "/feeder/dosen",
      },
      {
        component: "CNavGroup",
        name: "Mata Kuliah & Kurikulum",
        to: "/feeder/matkuldankurikulum",
        items: [
          {
            component: "CNavItem",
            name: "Kurikulum",
            to: "/feeder/matkuldankurikulum/kurikulum",
          },
          {
            component: "CNavItem",
            name: "Mata Kuliah",
            to: "/feeder/matkuldankurikulum/matakuliah",
          },
          {
            component: "CNavItem",
            name: "Mata Kuliah Kurikulum",
            to: "/feeder/matkuldankurikulum/matakuliahkurikulum",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Kelas Perkuliahan",
        to: "/feeder/kelasperkuliahan",
        items: [
          {
            component: "CNavItem",
            name: "Kelas Kuliah",
            to: "/feeder/kelasperkuliahan/kelaskuliah",
          },
          {
            component: "CNavItem",
            name: "Dosen Pengajar",
            to: "/feeder/kelasperkuliahan/dosenpengajar",
          },
          {
            component: "CNavItem",
            name: "Peserta Kelas",
            to: "/feeder/kelasperkuliahan/pesertakelas",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Nilai Perkuliahan",
        to: "/feeder/nilaiperkuliahan",
        items: [
          {
            component: "CNavItem",
            name: "Skala/Bobot Nilai",
            to: "/feeder/nilaiperkuliahan/bobotnilai",
          },
          {
            component: "CNavItem",
            name: "Nilai Mata Kuliah",
            to: "/feeder/nilaiperkuliahan/nilaimatkul",
          },
          {
            component: "CNavItem",
            name: "Nilai Transfer / Konversi",
            to: "/feeder/nilaiperkuliahan/konversi",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Data Mahasiswa",
    to: "/datamahasiswa",
    items: [
      {
        component: "CNavItem",
        name: "Daftar Dosen Wali",
        to: "/datamahasiswa/daftardosenwali",
      },
      {
        component: "CNavItem",
        name: "Status Registrasi",
        to: "/datamahasiswa/statusregistrasi",
      },
      {
        component: "CNavItem",
        name: "Mahasiswa",
        to: "/datamahasiswa/mahasiswa_baa",
      },
      {
        component: "CNavGroup",
        name: "Laporan Studi",
        to: "/datamahasiswa/laporanstudi",
        items: [
          {
            component: "CNavItem",
            name: "Laporan IPK Per Semester",
            to: "/datamahasiswa/laporanstudi/laporanipksemester",
          },
          {
            component: "CNavItem",
            name: "Detail Laporan IPK Per Semester",
            to: "/datamahasiswa/laporanstudi/detailipk",
          },
          {
            component: "CNavItem",
            name: "Laporan IPK Per Tahun Lulus",
            to: "/datamahasiswa/laporanstudi/laporanipk",
          },
          {
            component: "CNavItem",
            name: "Detail Laporan IPK Per Tahun Lulus",
            to: "/datamahasiswa/laporanstudi/detailpertahun",
          },
          {
            component: "CNavItem",
            name: "Lama Studi",
            to: "/datamahasiswa/laporanstudi/lamastudi",
          },
          {
            component: "CNavItem",
            name: "Detail Lama Studi",
            to: "/datamahasiswa/laporanstudi/detaillamastudi",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Presensi",
        to: "/presensi_baa",
        items: [
          {
            component: "CNavItem",
            name: "Cetak Daftar Hadir",
            to: "presensi_baa/cetakdaftarhadir",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi_baa",
    items: [
      {
        component: "CNavItem",
        name: "Cetak Daftar Hadir",
        to: "presensi_baa/cetakdaftarhadir",
      },
      {
        component: "CNavItem",
        name: "Berita Acara Perkuliahan",
        to: "presensi_baa/beritaacaraperkuliahan",
      },
      {
        component: "CNavGroup",
        name: "Kehadiran Mahasiswa",
        to: "/presensi_baa/kehadiranmahasiswa",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Mahasiswa",
            to: "/presensi_baa/kehadiranmahasiswa/lihatmahasiswa",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Perkuliahan",
        to: "/presensi_baa/Perkuliahan",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Perkuliahan",
            to: "/presensi_baa/Perkuliahan/lihatperkuliahan",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Nilai",
        to: "/presensi_baa/nilai",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Input Nilai",
            to: "/presensi_baa/nilai/jadwalinputnilai",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan Jadwal",
        to: "/presensi_baa/bantuanjadwal",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Dosen",
            to: "/presensi_baa/bantuanjadwal/jadwaldosen",
          },
        ],
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "CAMABA",
  },
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb-camaba",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb-camaba/beranda",
      },
      {
        component: "CNavItem",
        name: "Alur Sistem",
        to: "/pmb-camaba/alursistem",
      },
      {
        component: "CNavItem",
        name: "Status Pembayaran",
        to: "/pmb-camaba/statuspembayaran",
      },
      {
        component: "CNavItem",
        name: "Mengisi Data Peserta",
        to: "/pmb-camaba/mengisidatapeserta",
      },
      {
        component: "CNavItem",
        name: "Dokumen Peserta PMB",
        to: "/pmb-camaba/dokumenpeserta",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb-camaba/undurdiri",
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "BK",
  },
  {
    component: "CNavGroup",
    name: "TAK BK",
    to: "/tak_bk",
    items: [
      {
        component: "CNavItem",
        name: "Daftar TAK Mahasiswa",
        to: "/tak_bk/daftartak",
      },
      {
        component: "CNavItem",
        name: "Pengaturan Poin",
        to: "/tak_bk/pengaturanpoin",
      },
      {
        component: "CNavItem",
        name: "Cetak TAK",
        to: "/tak_bk/cetaktak",
      },
      {
        component: "CNavItem",
        name: "Cetak SKPI",
        to: "/tak_bk/cetakskpi",
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Admin Kuesioner",
  },
  {
    component: "CNavGroup",
    name: "Survey",
    to: "/survey_admin",
    items: [
      {
        component: "CNavGroup",
        name: "Kuesioner",
        to: "/survey_admin/kuesioner",
        items: [
          {
            component: "CNavItem",
            name: "Data Kuesioner",
            to: "/survey_admin/kuesioner/datakuesioner",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Hasil Kuesioner EDOM",
        to: "/survey_admin/kuesioneredom",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Dosen",
            to: "/survey_admin/kuesioneredom/laporandosen",
          },
          {
            component: "CNavItem",
            name: "Laporan Mata Kuliah",
            to: "/survey_admin/kuesioneredom/laporanmatkul",
          },
          {
            component: "CNavItem",
            name: "Laporan Program Studi",
            to: "/survey_admin/kuesioneredom/laporanprodi",
          },
          {
            component: "CNavItem",
            name: "Laporan Matakuliah Per Fakultas",
            to: "/survey_admin/kuesioneredom/laporanmatkulperfakultas",
          },
          {
            component: "CNavItem",
            name: "Detail Laporan per Dosen",
            to: "/survey_admin/kuesioneredom/laporanperdosen",
          },
          {
            component: "CNavItem",
            name: "Laporan pertanyaan Terbuka ",
            to: "/survey_admin/kuesioneredom/laporanpertanyaanterbuka",
          },
          {
            component: "CNavItem",
            name: "Grafik Dosen Per Program Studi",
            to: "/survey_admin/kuesioneredom/grafikdosen",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Hasil Kuesioner Kepuasan Pegawai",
        to: "/survey_admin/kepuasanpegawai",
        items: [
          {
            component: "CNavItem",
            name: "Kepuasan Universitas",
            to: "/survey_admin/kepuasanpegawai/universitas",
          },
          {
            component: "CNavItem",
            name: "Kepuasan per Warek",
            to: "/survey_admin/kepuasanpegawai/perwarek",
          },
          {
            component: "CNavItem",
            name: "Kepuasan per Fakultas",
            to: "/survey_admin/kepuasanpegawai/perfakultas",
          },
          {
            component: "CNavItem",
            name: "Kepuasan per Program Studi",
            to: "/survey_admin/kepuasanpegawai/perprodi",
          },
          {
            component: "CNavItem",
            name: "Kepuasan per Direktorat",
            to: "/survey_admin/kepuasanpegawai/perdirektorat",
          },
          {
            component: "CNavItem",
            name: "Kepuasan per Unit",
            to: "/survey_admin/kepuasanpegawai/perunit",
          },
        ],
      },
    ],
  },
  {
    component: "CNavTitle",
    name: "Admin PPM",
  },
  {
    component: "CNavGroup",
    name: "Pengaturan",
    to: "/admin-ppm/pengaturan",
    items: [
      {
        component: "CNavItem",
        name: "Pengaturan Event Kegiatan",
        to: "/admin-ppm/pengaturan/eventkegiatan",
      },
    ],
  },
  {
    component: "CNavItem",
    name: "Pengajuan Jurnal",
    to: "/admin-ppm/pengajuanjurnal",
  },

  {
    component: "CNavTitle",
    name: "Admin SDM",
  },
  {
    component: "CNavGroup",
    name: "Struktur Organisasi",
    to: "/struktur",
    items: [
      {
        component: "CNavItem",
        name: "Lihat Struktur Organisasi",
        to: "/struktur/lihatstruktur",
      },
      {
        component: "CNavItem",
        name: "Posisi Struktur Organisasi",
        to: "/struktur/Posisistruktur",
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Admin PPM",
  },
  {
    component: "CNavGroup",
    name: "Admin PPM",
    to: "/admin-ppm",
    items: [
      {
        component: "CNavGroup",
        name: "Acara",
        to: "/admin-ppm/acara",
        items: [
          {
            component: "CNavItem",
            name: "Buat Pengumuman",
            to: "/admin-ppm/acara/buatpengumuman",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Laporan Penelitian",
        to: "/admin-ppm/laporanpenelitian",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Semua Jurnal",
            to: "/admin-ppm/laporanpenelitian/lihatsemuajurnal",
          },
          {
            component: "CNavItem",
            name: "Laporan Penelitian Dosen",
            to: "/admin-ppm/laporanpenelitian/laporanpenelitiandosen",
          },
        ],
      },
    ],
  },

  {
    component: "CNavTitle",
    name: "Admin LAAK",
  },
  {
    component: "CNavGroup",
    name: "Kelola SK",
    to: "/kelolask",
    items: [
      {
        component: "CNavItem",
        name: "Terbitkan SK",
        to: "/kelolask/terbitkansk",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Daftar TA",
    to: "/daftartapa",
    items: [
      {
        component: "CNavItem",
        name: "Status TA/PA Mahasiswa",
        to: "/daftartapa/statustapa",
      },
      {
        component: "CNavItem",
        name: "Registrasi Mata Kuliah",
        to: "/daftartapa/regislaak",
      },
      {
        component: "CNavItem",
        name: "Unggah Mahasiswa Siap Sidang",
        to: "/daftartapa/unggahmahasiswa",
      },
      {
        component: "CNavItem",
        name: "Rakap TA/PA Mahasiswa ",
        to: "/daftartapa/rekapmahasiswa",
      },
      {
        component: "CNavItem",
        name: "Mahasiswa yang lulus Sidang Akademik untuk Siap Sidang ",
        to: "/daftartapa/mahasiswayanglulus",
      },
      {
        component: "CNavItem",
        name: "Lihat Dosen Pembimbing di Kelompok Keahlian",
        to: "/daftartapa/lihatdosen",
      },
      {
        component: "CNavItem",
        name: "Finaltask KK Advisor Detail",
        to: "/daftartapa/finaltask",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Sidang",
    to: "/sidang",
    items: [
      {
        component: "CNavItem",
        name: "Final Task Checklist Sidang",
        to: "/sidang/finaltask",
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
