<template>
  <div>
    <AppSidebar />
    <div
      class="wrapper d-flex flex-column min-vh-100"
      style="background-color: #d2d0e8"
    >
      <AppHeader />

      <div class="body flex-grow-1 px-md-2">
        <div class="container-fluid">
          <router-view />
        </div>
      </div>

      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from "@coreui/vue";
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppSidebar from "@/components/AppSidebar.vue";
import AppCard from "@/components/AppCard.vue";

export default {
  name: "DefaultLayout",
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
    AppCard,
  },
};
</script>
